@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;500;600;700');


/* -------------------------------- */
/* Vars */
/* -------------------------------- */

:root {

  --whitePrimary: #ffffff;
  --whiteSecondary: #e5e5e5;
  --whiteTertiary: #f8f8f8;

  --grayPrimary: #2D2D2D;
  --graySecondary: #6D6D6D;
  --grayTertiary: #DCDCDC;
  --grayLight: #F5F5F5;

  --activePrimary: #BC0181;
  --activeTertiary: #fafcfc;

  --activeAlt: #69049D;

  --paddingPrimary: 15px;
  --paddingSecondary: 30px;
  --paddingTertiary: 5px;

  --maxWidth: 1200px;
}


/* -------------------------------- */
/* Base */
/* -------------------------------- */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--grayPrimary);
  background-color: #F9F9F9;
  /* min-width: 1042px; */
  /* min-width: 800px;
  overflow-x: scroll; */
}

.outfit {
  font-family: 'Outfit', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
  margin: initial;
  padding: initial;
  color: var(--grayPrimary);
}

h1,
h4 {
  margin-bottom: 10px;
}

h2.outfit {
  font-weight: 500;
}

h3.v2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 400;
}

h4.v2 {
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 400;
}

button {
  text-align: initial;
  font-size: initial;
  line-height: initial;
  margin: initial;
  padding: initial;
  border: initial;
  background-color: initial;
  cursor: pointer;
}

p {
  margin-bottom: 5px;
  color: var(--grayPrimary);
  line-height: 1.4rem;
}

p.p-secondary {
  color: var(--graySecondary);
}

p.small {
  font-size: .9rem;
}

p.smaller {
  font-size: .8rem;
}


.error-box p,
.error-box a {
  color: var(--activePrimary);
}



/* Flex */

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}



/* Borders */
.border-element-round {
  border: 1px solid var(--whiteSecondary);
  border-radius: 10px;
  background-color: var(--whitePrimary);
}


/* Padding */
.padding-primary {
  padding: var(--paddingPrimary);
}

.padding-secondary {
  padding: var(--paddingSecondary);
}

.padding-tertiary {
  padding: var(--paddingTertiary);
}


/* Misc */
.pointer {
  cursor: pointer;
}

.color-secondary {
  color: var(--graySecondary);
}

.edit-link {
  color: var(--graySecondary);
  text-decoration: none;
}

.edit-link:hover {
  color: #0a7add;
}

.flow-speak-link {
  color: var(--grayPrimary);
}

.flow-speak-link:hover {
  color: var(--activePrimary);
}




/* -------------------------------- */
/* Layout */
/* -------------------------------- */

.body-wrapper {
  margin-bottom: 90px;
}

.body-container {
  width: 100%;
  box-sizing: border-box;
  max-width: var(--maxWidth);
  padding: 30px;

  min-height: calc(100vh - 150px);
}




/* -------------------------------- */
/* Inputs and Buttons */
/* -------------------------------- */

.field {
  margin: 15px 0 20px 0;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
select {
  padding: 8px;
  border: 1px solid var(--grayTertiary);
  border-radius: 5px;
  width: 250px;
}

input[type='checkbox'] {
  width: auto;
  margin: 0 10px 5px 0;
}

.search-bar-container {
  justify-content: flex-end;
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;
}

.search-bar-container input {
  width: 100%;
  padding-right: 35px;
}

.clear-search-button {
  margin-left: -34px;
  margin-top: 0px;
  height: 33px;
  width: 34px;
  padding-left: 6px;
  padding-top: 3px;
}

.clear-search-button:hover .icon {
  background-image: url('../images/icons/icon-cancel-fill.png');
}


.button {
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--whitePrimary);
  border: none;
  background-color: var(--activePrimary);
}

.button:hover {
  box-shadow: 0px 0px 5px 0px rgba(39, 1, 23, 0.2);
}

.button:active {
  opacity: .85;
}

.button.primary {
  display: flex;
  justify-content: center;
  min-width: 120px;
  padding: 10px 30px;
}

.button.secondary {
  display: flex;
  justify-content: center;
  min-width: 120px;
  padding: 8px 15px;
}

.button.tertiary {
  display: flex;
  justify-content: center;
  min-width: 130px;
  padding: 8px 15px;
  font-family: 'Outfit', sans-serif;
  background-color: var(--grayPrimary);
}

.button.disabled {
  opacity: .5;
}

.button.is-loading:after {
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loading-ring 1.2s linear infinite;
}

@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button.link {
  border-bottom: 1px solid var(--whitePrimary);
}

button.link:hover {
  border-bottom: 1px solid var(--whiteSecondary);
}

button.link.active {
  font-weight: bold;
}

button.link-secondary {
  color: var(--graySecondary);
}


button.export {
  /* margin-left: 15px; */
  padding: 5px 15px;
  font-size: .9rem;
  color: var(--grayPrimary);
  border: 1px solid var(--graySecondary);
  background-color: var(--whitePrimary);
}



/* -------------------------------- */
/* Icons */
/* -------------------------------- */

.icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.icon-cancel {
  background-image: url('../images/icons/icon-cancel.png');
}

.icon-cancel-fill {
  background-image: url('../images/icons/icon-cancel-fill.png');
}

.icon-circle-arrow {
  background-image: url('../images/icons/icon-circle-arrow.png');
}

.icon-circle-arrow-fill {
  background-image: url('../images/icons/icon-circle-arrow-fill.png');
}

.icon-circle-ok {
  background-image: url('../images/icons/icon-circle-ok.png');
  cursor: initial;
}

.icon-circle-ok-fill {
  background-image: url('../images/icons/icon-circle-ok-fill.png');
  cursor: initial;
}

.icon-view {
  width: 22px;
  height: 22px;
  background-image: url('../images/icons/icon-eye.png');
}

.icon-view-fill {
  width: 22px;
  height: 22px;
  background-image: url('../images/icons/icon-eye-fill.png');
}

.icon-key {
  background-image: url('../images/icons/icon-key.png');
}

.icon-printer {
  background-image: url('../images/icons/icon-printer.png');
}

.icon-logout {
  width: 22px;
  height: 16px;
  background-image: url('../images/icons/icon-logout.png');
  opacity: .8;
}

.icon-edit {
  width: 22px;
  height: 16px;
  background-image: url('../images/icons/icon-edit.png');
  opacity: .8;
}

.icon-info {
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  background-image: url('../images/icons/icon-info.png');
}

.icon-play {
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  background-image: url('../images/icons/icon-audio-full-dark.png');
}

.icon-chevron-up {
  background-image: url('../images/icons/icon-chevron-up.png');
}

.icon-chevron-down {
  background-image: url('../images/icons/icon-chevron-down.png');
}

.icon-chevron-left {
  background-image: url('../images/icons/icon-chevron-left.png');
}

.icon-chevron-right {
  background-image: url('../images/icons/icon-chevron-right.png');
}

.icon-resend {
  background-image: url('../images/icons/icon-resend.png');
}



/* -------------------------------- */
/* Loading & Blank State */
/* -------------------------------- */

.loading-container {
  margin: 30px 0;
}

.loading-spinner {
  width: 30;
  height: 40px;
  margin-bottom: 5px;
}

.loading-spinner:after {
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid var(--graySecondary);
  border-color: var(--graySecondary) transparent var(--graySecondary) transparent;
  animation: loading-ring 1.1s linear infinite;
}

.table td.td-blank-state {
  height: 140px;
  text-align: center;
  background-color: var(--whitePrimary);
}

.table td.td-id {
  width: 60px;
}

.table td.td-icon {
  width: 30px;
}


/* -------------------------------- */
/* Modal */
/* -------------------------------- */

.modal-wrapper {
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal-container {
  width: 85%;
  min-width: 500px;
  max-width: 1000px;
  height: 85vh;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0px 8px 0px rgb(0,0,0, .5);
  background-color: var(--whitePrimary);
}

.modal-flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 -30px 0 -30px;
  padding: 0 30px 15px 30px;
  border-bottom: 1px solid var(--grayTertiary);
}

.modal-body {
  overflow-y: scroll;
  flex-grow: 1;
}

/* .modal-footer {

} */

.modal-body button.export {
  margin-left: initial;
}




/* -------------------------------- */
/* Tabs */
/* -------------------------------- */

.tabs-container {
  justify-content: flex-start;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--grayTertiary);
}

.tab {
  margin-right: -1px;
  margin-bottom: -1px;
  padding: 8px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid var(--grayTertiary);
}

.tab.active {
  font-weight: bold;
  border-bottom: 1px solid var(--whitePrimary);
}





/* -------------------------------- */
/* Table */
/* -------------------------------- */

table, th, td, tr {
  border-collapse: collapse;
  font-size: .9rem;
}

.table-wrapper {
  width: 100%;
}

.table {
  width: 100%;
  border: 1px solid var(--whiteSecondary);
  background-color: var(--whitePrimary);
}

.table thead {
  border-bottom: 1px solid var(--whiteSecondary);
  background-color: #e2ebec;
}

.table thead tr {
  font-weight: 500;
  /* background-color: var(--whitePrimary) !important; */
}

.table tr:nth-child(even) {
  background-color: var(--activeTertiary);
}

.table td {
  box-sizing: border-box;
  /* padding: 8px 8px; */
  padding: 12px;
}

.table td.centered {
  text-align: center;
}

.table thead td {
  padding: 15px 8px;
}



/* -------------------------------- */
/* Table Learners Modern */
/* -------------------------------- */

.table-learner-row {
  margin-bottom: 5px;
  padding: 15px;
  border-radius: 3px;
  background-color: var(--whitePrimary);
}

.table-learner-row.header {
  width: auto;
  padding-bottom: 10px;
  font-weight: bold;
  background-color: transparent;
}

.table-learner-row-one {
  flex-grow: 1;
}

.table-learner-row-data {
  width: 36%;
}

.table-learner-row-data:nth-child(3) {
  width: 24%;
}

.table-learner-row-data .gray-text,
.gray-text {
  color: var(--graySecondary);
}

.table-learner-row-data p.seconday-info {
  margin-left: 10px;
  margin-bottom: 0px;
  font-size: .8rem;
}

.table-learner-row-data p.seconday-info span {
  margin-left: 1px;
  margin-right: 1px;
}

.table-learner-row-email {
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-learner-row-actions {
  width: 80px;
}

.table-learner-row .progress-bar-outer,
.table-learner-row .progress-bar-inner {
  max-width: 100px;
}

.unhas-course-data {
  margin-bottom: 15px;
}

.unhas-course-data p {
  font-size: .9rem;
  line-height: 1rem;
}

.unhas-course-data:last-of-type {
  margin-bottom: initial;
}

.unhas-course-data-general-english {
  width: fit-content;
  margin-top: 5px;
  padding: 5px 10px 0px 10px;
  border-radius: 5px;
  border: 1px solid var(--grayTertiary);
}

.unhas-course-data-general-english span {
  display: inline-block;
  width: 90px;
}

.unhas-course-data-general-english span:first-of-type {
  margin-bottom: 5px;
}

.table-learner-row-data .unhas-course-data-general-english p.seconday-info {
  margin-left: initial;
}


/* -------------------------------- */
/* Table Learners View Responses */
/* -------------------------------- */

.show-responses-container {
  margin: 15px;
}

.show-response-container {
  padding: 15px;
  margin: 15px 0;
  border-radius: 5px;
  background-color: var(--grayLight);
}

.show-response-container:last-of-type {
  margin-bottom: initial;
}





/* -------------------------------- */
/* Login, Register, & Onboarding */
/* -------------------------------- */

.start-wrapper {
  justify-content: flex-start;
  background-color: var(--whitePrimary);
}

.start-left-column {
  width: 30vw;
  min-width: 25vw;
  max-width: 600px;
  height: 100vh;
  padding: 60px 0;

  background-color: #BC0181;
  background-image: url('../images/start-background.jpg');
  background-size: cover;
  background-position: center;
}

.start-left-column.onboarding {
  background-color: #e2bdd6;
  background-image: url('../images/start-background-opacity.jpg');
}

.start-left-inner {
  height: 100%;
}

.start-left-column .header {
  background-color: transparent;
}

.start-left-inner .header-container .header-logo {
  margin-left: initial;
  padding: initial;
}

.start-left-column .logo-lockup {
  width: 90px;
}

.start-left-column p.header-admin {
  font-size: 1.125rem;
}

.start-right-column {
  flex-grow: 1;
  height: 100vh;
  overflow-y: scroll;
}

.start-right-inner {
  align-items: flex-start;
  min-height: calc(100% - 120px);
  padding: 60px;
}

.start-right-inner .header-container .header-logo {
  margin-left: initial;
  padding: initial;
}

.start-right-inner form {
  /* margin: 60px 0; */
  margin-top: 60px;
}

.start-right-inner .field {
  margin-bottom: 0px;
}

.start-right-inner .field.with-bottom-margin {
  margin-bottom: 30px;
}

.start-right-inner .field p {
  margin-top: 5px;
}

.start-right-inner .error-box {
  max-width: 500px;
}

.start-right-inner .course-chooser-wrapper {
  margin-top: 45px;
}

.start-link-container {
  margin-top: 120px;
}

.start-link-container p,
.start-link-container a {
  font-size: .9rem;
  color: var(--graySecondary);
}


.onboarding-steps-container {
  margin-top: 90px;
}

.onboarding-step-number {
  justify-content: center;
  width: 45px;
  height: 45px;
  margin-right: 10px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--graySecondary);
  border-radius: 100px;
  border: 2px solid var(--graySecondary);
}

.onboarding-step-container p {
  margin-bottom: initial;
  color: var(--graySecondary);
  font-size: 1.125rem;
}

.onboarding-step-container.active .onboarding-step-number,
.onboarding-step-container.active p {
  color: var(--activeAlt);
  border-color: var(--activeAlt);
}

.onboarding-step-container.completed .onboarding-step-number,
.onboarding-step-container.completed p {
  color: var(--grayPrimary);
  border-color: var(--grayPrimary);
}

.step-spacer {
  width: 2px;
  height: 30px;
  margin: 10px 0px 10px 22px;
  background-color: var(--graySecondary);
  opacity: .5;
}

.onboarding-step-container:last-of-type .step-spacer {
  display: none;
}


.onboarding-big-buttons-wrapper {
  align-items: stretch;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
}

button.onboarding-big-button,
div.onboarding-big-button {
  width: 100%;
  max-width: 300px;
  padding: 15px;
  text-align: center;
  border: 1px solid var(--grayTertiary);
  border-radius: 10px;
}

.onboarding-big-button.sub-text {
  padding-top: initial;
  text-align: left;
  border: initial;
}

button.onboarding-big-button.selected {
  border: 2px solid var(--activePrimary);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}


.onboarding-big-button.community {
  width: 40%;
}

.onboarding-big-button.premium {
  width: 60%;
}

.onboarding-big-button.premium {
  background-color: #F6F1FC;
}

.onboarding-big-button.sub-text.premium {
  background-color: initial;
}

.onboarding-big-button ul {
  margin: 30px 0;
  padding: initial;
  list-style: none;
}

.onboarding-big-button ul li {
  font-size: .8rem;
  line-height: 1.2rem;
}

button.button.onboarding-select-button {
  padding: 5px 30px;
  color: var(--grayPrimary);
  border: 1px solid var(--graySecondary);
  background-color: var(--whitePrimary);
}

button.button.onboarding-select-button.selected {
  color: var(--whitePrimary);
  background-color: var(--grayPrimary);
}



.skip-button {
  margin-left: 45px;
  color: var(--graySecondary);
  font-size: .9rem;
  font-style: italic;
}

.skip-button:hover {
  text-decoration: underline;
}




/* -------------------------------- */
/* Course chooser */
/* -------------------------------- */

.course-chooser-wrapper {
  margin-bottom: var(--paddingSecondary);
}

.course-chooser-container {
  align-items: stretch;
  gap: 15px;
  height: 500px;
  max-height: 75vh;
  overflow-y: scroll;
}

.course-choose-button {
  padding: 13px;
  border-radius: 10px;
  border: 2px solid var(--grayLight);
  background-color: var(--grayLight);
}

.course-choose-button.selected {
  border-color: var(--activePrimary);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.course-choose-button h5 {
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 500;
}

.course-choose-button p {
  font-size: .9rem;
  line-height: 1.2rem;
}

.course-choose-button p.course-description {
  color: var(--graySecondary);
}

.course-choose-button p.premium-message {
  margin-top: 10px;
  color: var(--activePrimary);
  font-style: italic;
}


/* -------------------------------- */
/* Purchase */
/* -------------------------------- */

.onboarding-pricing-buttons-container {
  justify-content: center;
  align-items: stretch;
  gap: 30px;
  margin-top: 30px;
}

.onboarding-price-button {
  margin-bottom: 5px;
  padding: 8px 24px;
  text-align: center;
  border-radius: 100px;
  border: 1px solid var(--grayPrimary);
}

.onboarding-price-button.selected {
  margin-bottom: 8px;
  border: 2px solid var(--activePrimary);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.pricing-sub-text {
  font-size: .8rem;
  line-height: 1rem;
  font-style: italic;
  color: var(--graySecondary);
}

.pricing-sub-text.discount-label {
  padding: 3px 8px;
  font-size: .7rem;
  color: var(--whitePrimary);
  border-radius: 30px;
  background-color: var(--activePrimary);
}

.onboarding-price-slider-container {
  width: 100%;
  margin: 30px 0;
  padding: 0 45px;
}

.subscription-count-input {
  width: 100px;
  margin: 15px 0;
  padding: 15px;
  text-align: center;
  font-size: 2rem;
  background-color: var(--grayLight);
}

.subscription-total-input {
  width: 120px;
  margin: 5px 0 15px 0;
  padding: 15px;
  text-align: center;
  font-size: 1.5rem;
  background-color: var(--grayLight);
}

.volume-pricing-link {
   color: var(--graySecondary);
}


#checkout {
  width: 100%;
  margin-top: var(--paddingPrimary);
  /* max-width: 412px; */
}




/* -------------------------------- */
/* Header */
/* -------------------------------- */

.header {
  width: 100%;
  background-color: var(--whitePrimary);
}

.header-container {
  width: 100%;
  max-width: var(--maxWidth);
}

.header-container .header-logo {
  margin-left: var(--paddingPrimary);
  padding: var(--paddingPrimary);
}

.logo-lockup {
  width: 136px;
  height: 50px;
  margin-right: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  /* background-image: url('../images/FLOW-Lockup.jpg'); */
  background-image: url('../images/FLOW-Lockup-clear.png');
}

p.header-admin {
  font-family: 'Outfit', sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: initial;
}

.go-to-flow {
  padding: var(--paddingSecondary);
  margin-left: var(--paddingSecondary);
  border-left: 1px solid var(--grayTertiary);
}

.profile-name {
  padding: var(--paddingSecondary);
  border-left: 1px solid var(--grayTertiary);
}


/* -------------------------------- */
/* Footer */
/* -------------------------------- */

footer {
  margin: 30px 45px;
}

footer a {
  color: var(--grayPrimary)
}



/* -------------------------------- */
/* Organization Card */
/* -------------------------------- */

.organization-card {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 15px;
  /* margin: -30px -30px 45px -30px; */
  background-color: #fcfcfc;
  border-top: 1px solid var(--whiteSecondary);
  border-bottom: 1px solid var(--whiteSecondary);
}

.organization-card .header-container {
  padding-left: 30px;
}

.organization-card h1 {
  font-size: 1.75em;
  margin-bottom: 0px;
}

.org-change-button {
  margin-left: 15px;
  font-size: .9rem;
  font-style: italic;
}

.org-change-button:hover {
  text-decoration: underline;
}

.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0, .1);
}

.loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 200px;
  padding: 30px;
  font-weight: bold;
  border-radius: 10px;
  background-color: var(--whitePrimary);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}





/* -------------------------------- */
/* Groups */
/* -------------------------------- */

.group-cards-container {
  gap: 30px;
  flex-wrap: wrap;
  align-items: stretch;
}

.group-card {
  width: calc(50% - 15px);
}



/* -------------------------------- */
/* Learner Stats */
/* -------------------------------- */

.stat-box-container {
  align-items: stretch;
  flex-grow: 1;
  gap: 15px;
  height: 182px;
}

.stat-box {
  flex-grow: 1;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid var(--grayTertiary);
  background-color: #FDFDFD;
}

.stat-box .stat-label {
  text-align: center;
}

.stat-box h4 {
  font-size: 2.5rem;
}

.stat-box small {

}



/* -------------------------------- */
/* View Learner Print */
/* -------------------------------- */

@media print {

  .body-wrapper {
    margin-bottom: 0px;
  }

  .header,
  .body-container,
  .organization-card,
  footer,
  .icon-printer,
  .icon-cancel-fill {
    display: none;
  }

  .modal-wrapper {
    position: initial;
    background-color: transparent;
  }

  .modal-container {
    width: 100%;
    height: 100%;
    margin-top: 30px;
    box-shadow: none;
    border: none;
  }

  .modal-body {
    overflow-y: visible;
  }

  .icon.icon-circle-ok::after,
  .icon.icon-circle-ok-fill::after {
    content: 'Yes';
  }

  .table tr:nth-child(odd) {
    background-color: initial;
  }

  .assess-response {
    break-inside: avoid;
  }

}



/* -------------------------------- */
/* Progress Bar */
/* -------------------------------- */

.progress-bar-outer {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: #EBB3D9;
}

.progress-bar-inner {
  height: 10px;
  border-radius: 5px;
  background-color: var(--activePrimary);
}







/* -------------------------------- */
/* Assessments */
/* -------------------------------- */

.assess-sub-head-button {
  padding: 5px;
  margin-right: 15px;
}

.assess-sub-head-button.selected {
  font-weight: bold;
  border-bottom: 1px solid var(--graySecondary);
}

.assess-sub-head-button p {
  font-size: 1.25rem;
}

.assess-card-container {
  align-items: stretch;
  justify-content: flex-start;
  gap: 30px;
  padding: 30px 0;
}

.assess-card {
  min-width: 230px;
  min-height: 362px;
  border-radius: 5px;
  border: 1px solid var(--grayTertiary);
  background-color: var(--grayLight);
}

.assess-card.highlighted {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  border-color: var(--graySecondary);
}

.assess-card-head,
.assess-card-body {
  padding: 30px;
}

.assess-card-body {
  width: 100%;
  border-top: 1px solid var(--grayTertiary);
}

.assess-card-score-row {
  width: 100%;
  justify-content: space-between;
}

p.assess-date-p {
  font-size: .9rem;
  font-style: italic;
  text-align: center;
}

.assess-responses-container {
  border-top: 1px solid var(--grayTertiary);
}

.assess-responses-container h3 {
  margin: 30px 0;
}

.assess-response {
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.assess-response:last-of-type {
  border-bottom: initial;
}

p.assess-score-heading {
  margin-top: 20px;
  font-weight: 500;
}

p.assess-score-heading span {
  display: inline-block;
  width: 130px;
}

.assess-response i {
  color: var(--graySecondary);
}


.assess-blank-state {
  max-width: 500px;
  margin-top: 120px;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  background-color: var(--whitePrimary);
}

.assess-blank-state a {
  color: #0a7add;
}

.p-assess-overall-score {
  margin-top: 5px;
  font-size: .8rem;
  font-style: italic;
  color: var(--graySecondary);
}




/* -------------------------------- */
/* PrimeReact */
/* -------------------------------- */

.prime-table-simple-wrapper {
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--grayTertiary);
}

.prime-table-simple-wrapper thead {
  background-color: #e2ebec;
}

.prime-table-simple-wrapper .p-datatable .p-datatable-tbody > tr:last-of-type > td {
  border-bottom-left-radius: 5px;
  border-bottom: initial;
}

.prime-table-simple-wrapper .p-datatable .p-sortable-column.p-highlight {
  border-top-left-radius: initial;
  border-top-right-radius: initial;
}

.prime-table-simple-wrapper .p-datatable .p-sortable-column.p-highlight:first-of-type {
  border-top-left-radius: 5px;
}

.prime-table-simple-wrapper .p-datatable .p-sortable-column.p-highlight:last-of-type {
  border-top-right-radius: 5px;
}

.prime-table-simple-wrapper .p-row-toggler[aria-expanded="false"] {
  transform: rotate(270deg);
}

.prime-table-simple-wrapper .p-row-toggler[aria-expanded="true"] {
  transform: rotate(0deg);
}


.p-paginator .p-paginator-current {
  margin-left: 60px;
  margin-right: 60px;
  font-size: .9rem;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr {
  color: var(--grayPrimary);
}

.p-datatable thead > tr > th {
  border: initial;
  background-color: initial;
}

.p-datatable .p-sortable-column.p-highlight {
  text-decoration: underline;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #eaf4f5;
}

.p-datatable .p-datatable-tbody > tr > td {
  user-select: all;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background-color: var(--activeTertiary);
}







/* -------------------------------- */
/* Slider */
/* -------------------------------- */

.range-slider-input {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  padding: initial;
  margin: 0 15px;
  border: initial;
  outline: none;
  border-radius: 10px;
  background: var(--grayPrimary);
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.range-slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  border: 2px solid var(--grayPrimary);
  background: var(--whitePrimary);
  cursor: pointer;
}

.range-slider-input::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 100px;
  border: 2px solid var(--grayPrimary);
  background: var(--whitePrimary);
  cursor: pointer;
}

.slider-values-container {
  width: 100%;
  padding: 0 15px 0 5px;
  margin-top: 15px;
}

.slider-values-container span {
  width: 1%;
  font-size: .8rem;
  text-align: center;
}


.slider-values-container span:first-of-type {
  margin-left: -3px;
}
/*
.slider-values-container span:last-of-type {
  width: 60px;
  margin-right: -55px;
} */





/* -------------------------------- */
/* Group Details */
/* -------------------------------- */

.group-details-wrapper {
  margin-top: 30px;
}

.group-details-container {
  align-items: stretch;
  margin-top: 15px;
  padding: var(--paddingPrimary);
  border-radius: 10px;
  background-color: var(--whitePrimary);
}

.group-details-container small {
  color: var(--graySecondary);
  font-style: italic;
}

.group-detail-item {
  padding: 0 5px;
  text-align: center;
}

.group-detail-divider {
  width: 1px;
  background-color: var(--graySecondary);

}

.group-details-left {
  align-items: flex-start;
  justify-content: space-evenly;
  padding-right: var(--paddingSecondary);
  border-right: 1px solid var(--grayTertiary);
}

.group-details-right {
  width: 100%;
  align-items: stretch;
  gap: 30px;
  padding-left: var(--paddingSecondary);
}

.group-details-right-left {
  gap: 30px;
  width: 100%;
  max-width: 150px;
}

.group-details-right-right {
  flex-grow: 1;
  gap: 30px;
}

.group-details-right-left .stat-box {
  width: 100%;
  min-width: 100px;
  padding: var(--paddingPrimary);
}

.group-details-right-right .stat-box {
  padding: var(--paddingPrimary);
}

.group-details-right-right .stat-box h4 {
  font-size: 1.75rem;
}

.group-details-right-right .lesson-data-chart-container {
  width: 99% !important;
}

.group-details-right-right .top-stats {
  align-items: 'stretch';
  width: 100%;
  gap: var(--paddingSecondary);
  padding-left: 2px;
  padding-right: 2px;
}




/* -------------------------------- */
/* Groups - Welcome card */
/* -------------------------------- */

.welcome-card-container {
  margin-top: var(--paddingSecondary);
  padding: 60px 30px;
  text-align: center;
}




/* -------------------------------- */
/* Invite learners */
/* -------------------------------- */

.invite-learners-wrapper {
  margin-top: 90px;
}

.invite-learners-container {
  margin-top: var(--paddingPrimary);
  padding: 60px var(--paddingSecondary);
}

.invite-learners-container h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

.invite-learners-sets-message {
  margin-top: 10px;
}

.invite-learners-sets-message p,
.invite-learners-sets-message a {
  font-size: .9rem;
  color: var(--graySecondary);
}

